<template>
  <div class="t-cart">
    <!-- CART -->
    <div class="card">
      <div class="row">
        <div class="col-md-8 cart">
          <div class="title">
            <div class="row">
              <div class="col">
                <h4>
                  <b>{{ $t("page-cart.title") }}</b>
                </h4>
              </div>
              <!-- savatdagi dorilar sonini ko'rsatish -->
              <div class="col align-self-center text-right text-muted">
                {{ cartTotalProductCount }} {{ $t("page-cart.count") }}
              </div>
            </div>
          </div>

          <!-- Savatda dori bo'lmagan holda text chiqarish -->
          <h3 v-if="!cart.length" class="module-title font-alt">
            {{ $t("page-cart.empty-title") }}
          </h3>

          <!-- Items PRODUCTS -->
          <div
            v-for="(item, index) in cart"
            :key="index"
            class="row border-top border-bottom"
          >
            <div class="row main align-items-center">
              <div class="col-2">
                <img
                  class="img-fluid"
                  :src="require('@/assets' + item.product.image)"
                />
              </div>
              <div class="col">
                <div class="row text-muted">BioMed</div>
                <div class="row">{{ item.product.name[selectLanguage()] }}</div>
              </div>
              <div class="col align-center">{{ item.product.cost }}</div>
              <div class="col align-center">
                <a href="#none" @click="decrement(index)">
                  <!-- <i class="fas fa-minus"></i> -->
                  <b-icon icon="dash" font-scale="1.3" />
                </a>
                {{ item.quantity }}

                <a href="#none" @click="increment(index)">
                  <b-icon icon="plus" font-scale="1.3" />
                </a>
              </div>
              <div class="col align-center">
                <!-- {{ item.product.cost * item.quantity  }} -->
                {{ addValue(item.product.cost, item.quantity) }}
              </div>
              <div class="col align-center">
                <a href="#none" @click="remove(index)">
                  <b-icon icon="trash" font-scale="1.3" />
                </a>
                <!-- <span class="">&#10005;</span> -->
              </div>
            </div>
          </div>

          <!-- Back To products -->
          <div class="back-to-shop">
            <a href="products"
              >&leftarrow;
              <span class="text-muted">{{ $t("page-cart.back-to-shop") }}</span>
            </a>
          </div>
        </div>

        <!-- JAMI-->
        <div class="col-md-4 summary">
          <div>
            <h5>
              <b>{{ $t("page-cart.summary") }}</b>
            </h5>
          </div>
          <hr />

          <div class="row">
            <!-- Dorilar umumiy soni  -->
            <div class="col" style="padding-left: 0">
              <!-- {{ $t("page-cart.items") }} {{ cartTotalProductCount }} -->
              {{ $t("page-cart.items") }}
            </div>
            <!-- Umumiy Narx -->
            <div class="col text-right">{{ cartTotalProductCount }} EA</div>
          </div>

          <form role="form" @submit.prevent="submit">
            <!-- <p>{{ $t("page-cart.shipping") }}</p>
            <select>
              <option class="text-muted" >
                {{ $t("page-cart.standart") }} ${{ shippingPrice }}
              </option>
            </select> -->

            <!-- <p>GIVE CODE</p>
            <input id="code" placeholder="Enter your code" /> -->

            <!-- style="border-top: 1px solid rgba(0, 0, 0, 0.1); padding: 2vh 0" -->
            <div class="row" style="padding: 2vh 0">
              <div class="col">{{ $t("page-cart.total") }}</div>
              <div class="col text-right">
                {{ cartTotalPrice + shippingPrice }} sum
              </div>
            </div>

            <hr />

            <b-form-input
              id="name-input"
              type="text"
              v-model="name"
              :state="nameState"
              ref="refnameinput"
              :placeholder="$t('page-contact.name')"
            ></b-form-input>

            <input
              class="form-control"
              type="email"
              id="email"
              :placeholder="$t('page-contact.email')"
              v-model="email"
              data-validation-required-message="Please enter your email address."
            />

            <!-- <input
              class="form-control"
              type="phone"
              id="phone"
              name="phone"
              :placeholder="$t('page-contact.input-phone')"
              required
              v-model="phone"
              data-validation-required-message="Please enter your phone number."
            /> -->

            <!-- <b-form-input
              id="phone-input"
              v-model="phone"
              :state="phoneState"
              ref="refphoneinput"
              :placeholder="$t('page-contact.input-phone')"
            ></b-form-input> -->

            <VuePhoneNumberInput
              required
              clearable
              v-model="phone"
              default-country-code="UZ"
              no-example
              @update="onUpdatePhone"
            />

            <!-- Buyurtma qilish button -->
            <button class="btn" type="submit">
              <!-- <button class="btn" v-b-modal.modal-prevent-closing> -->
              {{ $t("page-cart.checkout") }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- <b-button v-b-modal.submit-success>Show Modal</b-button> -->

    <b-modal
      id="submit-success"
      ref="submit-success"
      title="BootstrapVue"
      hide-footer
      hide-header
    >
      <!-- <h4 class="my-4 text-center">Haridingiz uchun tashakkur!</h4>
      <h4 class="text-center">Siz bilan tez orada aloqaga chiqamiz.</h4> -->
      <h5 class="my-4 text-center">{{ $t("harid") }}</h5>
      <h5 class="text-center">{{ $t("aloqa") }}</h5>

      <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >Close Me</b-button
      >
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.t-cart {
  margin: 70px 70px;
  // font-family: "Roboto Condensed", sans-serif;
  // font-family: "Raleway", sans-serif;
  // font-family: "Cormorant";
  font-family: "Poppins", sans-serif;
}
body {
  background: #ddd;
  min-height: 100vh;
  vertical-align: middle;
  display: flex;
  font-family: sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
}

.title {
  margin-bottom: 5vh;
}

.card {
  margin: auto;
  max-width: 1260px;
  width: 90%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  border: transparent;
}

@media (max-width: 767px) {
  .t-cart {
    margin: 0px;
  }
  .card {
    margin: 3vh auto;
  }
}

.align-center {
  text-align: center;
  font-size: 16px;
}
.cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

@media (max-width: 767px) {
  .cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
  }
}

.summary {
  background-color: #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65);
}

@media (max-width: 767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}

.summary .col-2 {
  padding: 0;
}

.summary .col-10 {
  padding: 0;
}

.row {
  margin: 0;
}

.title b {
  font-size: 1.5rem;
}

.main {
  margin: 0;
  padding: 3vh 10px;
  width: 100%;
}

.col-2,
.col {
  padding: 0 0vh;
}

a {
  // padding: 0 1vh;
  padding: 6px 7px;
  font-size: 16px;
}

.close {
  //margin-left: auto;
  font-size: 0.7rem;
}

img {
  width: 3.5rem;
}

.back-to-shop {
  margin-top: 4.5rem;
}

h5 {
  margin-top: 4vh;
}

hr {
  margin-top: 1.25rem;
}

form {
  padding: 2vh 0;
}

select {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.5vh 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}

input {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

.btn {
  background-color: #000;
  border-color: #000;
  color: white;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}

.btn:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  // -webkit-user-select: none;
  transition: none;
}

.btn:hover {
  color: white;
}

a {
  color: black;
}

a:hover {
  color: black;
  text-decoration: none;
}

#code {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.253),
      rgba(255, 255, 255, 0.185)
    ),
    url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import i18n from "@/i18n";

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      shippingPrice: 0,
      name: "",
      email: "",
      phone: "",
      phoneData: null,
      submittedNames: [],
      startCheck: false,

      //       translations="{
      //   countrySelectorLabel: 'Code pays',
      //   countrySelectorError: 'Choisir un pays',
      //   phoneNumberLabel: 'Numéro de téléphone',
      //   example: 'Exemple :'
      // }"
    };
  },
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice", "cartTotalProductCount"]),

    nameState() {
      let valid = null;

      if (this.startCheck) {
        valid = this.name.length > 2 ? true : false;
      }

      return valid;
    },
    phoneState() {
      let valid = null;

      if (this.startCheck) {
        valid = this.phone.length > 5 ? true : false;
      }

      return valid;
    },
  },
  methods: {
    ...mapActions("cart", [
      "decrementItem",
      "incrementItem",
      "deleteItem",
      "deleteAllItems",
    ]),
    ...mapActions(["sendMessage"]),

    decrement(index) {
      this.decrementItem(index);
    },
    increment(index) {
      this.incrementItem(index);
    },
    remove(index) {
      this.deleteItem(index);
    },

    // Get Site Language
    selectLanguage() {
      console.log(this.$i18n.locale);
      if (this.$i18n.locale === "uz") {
        return 0;
      } else {
        return 1;
      }
    },

    // Get Now Date
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      // this.timestamp = dateTime;

      return dateTime;
    },

    // Check Input TextBox
    checkFormValidity() {
      if (this.cart.length === 0) {
        this.$notify.error({
          title: i18n.t("page-cart.warning"),
          message: i18n.t("page-cart.empty-title"),
        });
      } else {
        // const valid = this.$refs.form.checkValidity();
        // this.nameState = valid;

        const namevalid = this.name.length > 2 ? true : false;
        const phonevalid = this.phone.length > 5 ? true : false;

        // this.nameState = namevalid;
        // this.phoneState = phonevalid;
        // this.$refs.refnameinput.state = namevalid;
        // this.$refs.refphoneinput.state = phonevalid;
        // this.$refs.refphoneinput.state = this.phoneStateAfter;

        let valid = false;

        if ((namevalid == true) & (phonevalid == true)) {
          valid = true;
        }

        this.startCheck = true;

        return valid;
      }
    },

    // Phone Number
    onUpdatePhone(payload) {
      this.phoneData = payload;
    },

    // Send Message - SUBMIT
    submit() {
      if (!this.checkFormValidity()) {
        return;
      }

      let fullMessage = "[ZAKAZ " + this.getNow() + "] %0A";

      fullMessage += "------------------%0A";
      fullMessage += "Name: " + this.name + " %0A";
      fullMessage += "Phone: " + this.phoneData.formatInternational + " %0A";
      fullMessage += "Country Code: " + this.phoneData.countryCode + " %0A";
      fullMessage += "Email: " + this.email + " %0A";
      fullMessage += "%0A   Products %0A";

      this.cart.forEach((element, index) => {
        fullMessage +=
          index +
          1 +
          ". " +
          element.product.name[0] +
          " (" +
          element.product.cost +
          "$) - " +
          element.quantity +
          " ta %0A";
      });

      fullMessage += "------------------%0A%0A";
      fullMessage += "JAMI : %0A" + this.cartTotalProductCount + "ta dori %0A";
      fullMessage += "Narxi: " + this.cartTotalPrice + " so'm %0A";

      // console.log(fullMessage);
      // console.log(this.phoneData);
      this.sendMessage(fullMessage);

      this.clearCart();

      this.showAlert();
    },

    clearCart() {
      this.name = ""
      this.email = ""
      this.phone = null
      this.phoneData = null
      this.deleteAllItems();
    },

    showAlert() {
      this.$bvModal.show("submit-success");
    },

    hideModal() {
      // this.$refs['submit-success'].hide()
      this.$bvModal.hide("submit-success");
    },

    addValue(item1, item2) {
      const nnn = item1.replace(",", ".");
      const add = parseFloat(nnn) * item2;
      return add.toFixed(3);
    },
  },
};
</script>

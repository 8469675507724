import { render, staticRenderFns } from "./t-cart.vue?vue&type=template&id=46127723&scoped=true&"
import script from "./t-cart.vue?vue&type=script&lang=js&"
export * from "./t-cart.vue?vue&type=script&lang=js&"
import style0 from "./t-cart.vue?vue&type=style&index=0&id=46127723&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46127723",
  null
  
)

export default component.exports
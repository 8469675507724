<template>
  <div class="navar" v-if="useNavbar">
    <header>
      <a :href="`/${$i18n.locale}/`" class="brand">
        <!-- <img
          :src="
            isDefaultImage
              ? require('@/images/logo/logo_ign_white_nobg.png')
              : require('@/images/logo/logo_ign_nobg.png')
          "
          alt="Biomed Industry"
          class="img-brand"
        /> -->
        <div v-if="isDefaultImage">
          <!-- <h1 class="text_logo_white">IGN <br><span class="text_childlogo_white">Health Group</span></h1> -->
          <h1 class="text_logo_white">IGN</h1>
        </div>
        <div v-else>
          <h1 class="text_logo">IGN</h1>
        </div>
        <!-- <h2 class="text_logo">Health Group</h2> -->
      </a>

      <!-- Menu Button for Mobile Version -->
      <div
        class="menu-btn"
        :style="isEnable ? 'visibility: visible;' : 'visibility: hidden;'"
      >
        <b-icon :class="classIcon" icon="justify" font-scale="2"></b-icon>
      </div>

      <!-- <div class="navigation">
            <a href="#main" >Home</a>
            <a href="#abouts">About</a>
            <a href="#products">Skills</a>
            <a href="#services">Services</a>
            <a href="#work">Work</a>
            <a href="#contact">Contact</a>
        </div> -->



      <!-- Navigation -->
      <div :class="classNav">
        <ul class="nav">
          <li >
            <a :href="`/${$i18n.locale}/`">{{ $t("nav.home") }}</a>
          </li>

          <li v-if="isEnable" id="about">
            <!-- <a :href="`/${$i18n.locale}/about`">{{ $t("nav.about") }}</a> -->
            <a :href="`/${$i18n.locale}/#abouts`">{{ $t("nav.about") }}</a>
          </li>

          <li v-if="isEnable" id="product">
            <!-- <a :href="`/${$i18n.locale}/products`"> {{ $t("nav.product") }}</a> -->
            <a :href="`/${$i18n.locale}/#products`"> {{ $t("nav.product") }}</a>
          </li>

          <li v-if="isEnable" id="contact">
            <!-- <a :href="`/${$i18n.locale}/contact`">{{ $t("nav.contact") }}</a> -->
            <a :href="`/${$i18n.locale}/#contacts`">{{ $t("nav.contact") }}</a>
          </li>

          <!-- <li v-if="isEnable" id="faq">
            <a :href="`/${$i18n.locale}/faq`">{{ $t("nav.faq") }}</a>
          </li> -->

          <!-- LANGUAGE -->
          <li v-if="isEnable" id="language">
            <Language />
          </li>
          <!-- CART -->
          <!-- <li id="cart" v-if="isEnable">
            <a :href="`/${$i18n.locale}/cart`">
              <b-icon v-if="!cart.length" icon="bag" font-scale="1.2"></b-icon>
              <b-icon
                v-if="cart.length"
                icon="bag-check"
                font-scale="1.2"
              ></b-icon>
            </a>
          </li> -->
        </ul>
      </div>
    </header>
  </div>
</template>


<style scoped>
.text_logo {
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  /* font-family: 'Lucida Calligraphy', sans-serif; */
  font-family: "Lucida Unicode Calligraphy", sans-serif;
  font-size: 35px;
  padding-top: 10px;
  color: black;
}

.text_logo_white {
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  /* font-family: 'Lucida Calligraphy', sans-serif; */
  font-family: "Lucida Unicode Calligraphy", sans-serif;
  font-size: 35px;
  padding-top: 10px;
  padding-bottom: 0;
  color: white;
}

.text_childlogo_white {
  font-family: "Lucida Unicode Calligraphy", sans-serif;
  font-size: 20px;
  /* padding-top: 10px; */
  color: white;
}
</style>

<style lang="scss" scoped src="@/css/navbar_mainpage_sytle.scss">
</style>

<script>
import { mapState } from "vuex";
import Language from "../Titan/t-language-switcher.vue";
export default {
  data() {
    return {
      activeItem: "home",
      isDropdown: false,
      isHomePage: false,
      classNav: "navigation",
      classIcon: "iconClass",
      useNavbar: true,

      useBrandImage: "logo_BioMed_no_bg_white.png",
      whiteImage: "logo_BioMed_no_bg_white.png",
      orgImage: "logo_BioMed_no_bg.png",

      isDefaultImage: true,
    };
  },
  components: {
    Language,
  },
  methods: {
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
    },

    // created by bek
    removeItems: function (navigationItems) {
      navigationItems.forEach((navItem) => {
        navItem.classList.remove("active");
      });
    },

    smoothScroll: function (targetId) {
      // Ver 1
      // window.scrollTo({
      //     top: 1000,
      //     behavior: "smooth"
      // })

      // Ver 2
      // document.querySelector("#main").scrollIntoView({
      //     behavior:'smooth',
      //     block: "start"
      // })

      // Ver 3
      const targetPosition = document.querySelector(targetId).offsetTop;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      const duration = 1000;
      let start = null;
      window.requestAnimationFrame(step);

      function step(timestamp) {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        window.scrollTo(0, distance * (progress / duration) + startPosition);
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      }
    },

    handleScroll() {
      if (window.scrollY > 100) {
        return (this.isDefaultImage = false);
      }
      if (window.scrollY <= 100) {
        if (!this.defaultImage) {
          return (this.isDefaultImage = true);
        }
      }
    },
  },
  computed: {
    ...mapState("cart", ["cart"]),

    // isEnable() {
    //   var rout_title =
    //     this.$route.meta.title === undefined ? "home" : this.$route.meta.title;

    //   if (rout_title === "language") return false;
    //   else {
    //     return true;
    //   }
    // },
    isEnable() {
      console.log(this.$route.meta.id);
      console.log(this.$route.meta.title);
      console.log(this.$route.meta);

      var rout_title =
        this.$route.meta.id === undefined ? "home" : this.$route.meta.id;

      if (rout_title === "language") return false;
      else {
        return true;
      }
    },

  },
  created: function () {
    var rout_title =
      this.$route.meta.title === undefined ? "home" : this.$route.meta.title;

    // if (rout_title === "home") {
    //   this.useNavbar = false;
    //   this.classNav = "navigationHome";
    //   this.isHomePage = true;
    //   this.classIcon = "menu-btn-icon-Main";
    // } else {
    //   this.useNavbar = true;
    //   this.classNav = "navigation";
    //   this.isHomePage = false;
    //   this.classIcon = "menu-btn-icon";
    // }

    this.useNavbar = true;
    this.classNav = "navigation";
    this.isHomePage = false;
    this.classIcon = "menu-btn-icon";

    // navbarni scroll qiganda o'zgarishi
    window.addEventListener("scroll", function () {
      const header = this.document.querySelector("header");

      // qo'llanma - 1
      // if (window.scrollY > header.offsetHeight) {
      //   header.classList.add("sticky");
      // } else {
      //   header.classList.remove("sticky");
      // }
      // qo'llanma 2
      header.classList.toggle("sticky", window.scrollY > header.offsetHeight);
    });

    window.addEventListener("scroll", this.handleScroll);

    // Navbar active function
    window.addEventListener("load", () => {
      if (rout_title !== "language") {
        document.querySelector("#" + rout_title).classList.add("active");
      }
    });


  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    // #region
    const menuBtn = document.querySelector(".menu-btn");
    const navigation = document.querySelector("." + this.classNav);
    const navigationItems = document.querySelectorAll('.navigation a')

    // mobile versiyaga o'tkanda menu button function
    menuBtn.addEventListener("click", () => {
      menuBtn.classList.toggle("active");
      navigation.classList.toggle("active");
    });

    // mobile versiyada menu dan biror item bosilgandan keyin
    // menusini ko'zdan yoqotish va scroll qilish
    navigationItems.forEach((navItem) => {
      console.log('navigation itesm')
      navItem.addEventListener("click", () => {
      console.log('navigation itesm2')

        console.log(this.$route);
        menuBtn.classList.remove("active");
        navigation.classList.remove("active")
        navItem.classList.remove("active");
        // var targetId = navItem.getAttribute("href");

        // if (this.$route.hash === "#abouts") {
        //   // targetId = targetId.split("/").pop();
        //   // console.log('target id bu  : ')
        //   // console.log(targetId);
        //   menuBtn.classList.remove("active");
        //   navigation.classList.remove("active");

        //   // bosilgan itemni active qilish function
        //   this.removeItems(navigationItems);

        //   navItem.classList.add("active");

        //   // this.smoothScroll(targetId);
        // } else if (this.$route.hash === "#products") {
        //   //   this.removeItems(navigationItems);
        //   navigation.classList.remove("active")
        //   navItem.classList.remove("active");
        //   // document.querySelector(targetId).classList.add("active");
        // }
      });
    });
    // #endregion

 
  },
};
</script>

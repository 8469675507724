<template>
  <div class="t-contact" id="contacts">
    <div class="main">
      <section class="pt-40">
        <div class="container" data-aos="fade-down" data-aos-duration="1500">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="font-alt">{{ $t('page-contact.title') }}</h5>
              <br />
              <form
                id="contactForm"
                role="form"
                method="POST"
                @submit.prevent="submit"
              >
                <div class="form-group">
                  <b-form-input
                    id="name-input"
                    type="text"
                    v-model="name"
                    :state="nameState"
                    :placeholder="$t('page-contact.name')"
                  ></b-form-input>

                  <p class="help-block text-danger"></p>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="email"
                    id="email"
                    name="email"
                    :placeholder="$t('page-contact.email')"
                    v-model="email"
                    data-validation-required-message="Please enter your email address."
                  />
                  <p class="help-block text-danger"></p>
                </div>

                <div class="form-group">
                  <b-form-input
                    id="phone-input"
                    v-model="phone"
                    :state="phoneState"
                    :placeholder="$t('page-contact.input-phone')"
                  ></b-form-input>
                  <p class="help-block text-danger"></p>
                </div>

                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="7"
                    id="message"
                    name="message"
                    :placeholder="$t('page-contact.message')"
                    required="required"
                    v-model="message"
                    data-validation-required-message="Please enter your message."
                  ></textarea>
                  <p class="help-block text-danger"></p>
                </div>
                <!-- JO'NATISH BUTTON -->
                <div class="text-center">
                  <button
                    class="btn btn-block btn-round btn-d contact_btn"
                    id="cfsubmit"
                    type="submit"
                  >
                    {{ $t('page-contact.submit') }}
                  </button>
                </div>
              </form>

              <div
                class="ajax-response font-alt"
                id="contactFormResponse"
              ></div>
            </div>

            <div class="col-sm-6">
              <h5 class="font-alt">{{ $t('page-contact.addition-info') }}</h5>
              <!-- <br /> -->
              <p class="pt-10">{{ $t('page-contact.addition-info-text') }}</p>
              <hr />
              <h6 class="font-alt" style="font-style: bold">
                {{ $t('page-contact.address-title') }}
              </h6>
              <p>
                {{ $t('page-contact.address') }}
              </p>
              <h6 class="font-alt">{{ $t('page-contact.phone-title') }}</h6>
              <p>{{ $t('page-contact.phone') }}</p>

              <h6 class="font-alt">{{ $t('page-contact.time-title') }}</h6>
              <ul class="list-unstyled">
                <li>{{ $t('page-contact.hours') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="map-section">
        <div class="map" id="map">
          <p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.8391937852975!2d69.28822831542408!3d41.33410997926914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5fbe2f4d9c4028be!2zNDHCsDIwJzAyLjgiTiA2OcKwMTcnMjUuNSJF!5e0!3m2!1sru!2skr!4v1661258657976!5m2!1sru!2skr"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </p>
        </div>
      </section>
    </div>
    <b-modal id="modal-1" hide-footer hide-header>
      <t-alert :alertType="true" />
    </b-modal>

    <b-modal
      id="submit-success"
      ref="submit-success"
      title="BootstrapVue"
      hide-footer
      hide-header
    >
      <h5 class="my-4 text-center">{{ $t('murojat') }}</h5>
      <h5 class="text-center">{{ $t('aloqa') }}</h5>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >{{ $t('close') }}</b-button
      >
    </b-modal>
  </div>
</template>

<style lang="scss" scoped src="@/css/Main_style.scss"></style>

<script>
import { mapActions } from 'vuex'
import tAlert from './t-alert.vue'

export default {
  data () {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      startCheck: false,

      // Telegram BOT config
      token: '226413308:AAEqL6UkBaBAg9HanO8HKnzZY4mcZMaQ170',
      // token: "2087847433:AAFAA6x0LZlsUhKFl_miL0-UncIb7iSJKKI",
      chatId: 115242787 //Bek
      // chatId: 221276764,
    }
  },
  components: {
    tAlert
  },
  computed: {
    nameState () {
      let valid = null

      if (this.startCheck) {
        valid = this.name.length > 2 ? true : false
      }

      return valid
    },
    phoneState () {
      let valid = null

      if (this.startCheck) {
        valid = this.phone.length > 5 ? true : false
      }

      return valid
    }
  },
  methods: {
    ...mapActions(['sendMessage']),

    // Check Input TextBox
    checkFormValidity () {
      const namevalid = this.name.length > 2 ? true : false
      const phonevalid = this.phone.length > 5 ? true : false

      let valid = false

      if ((namevalid == true) & (phonevalid == true)) {
        valid = true
      }

      this.startCheck = true

      return valid
    },
    // Get Now Date
    getNow () {
      const today = new Date()
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time

      return dateTime
    },
    submit () {
      if (!this.checkFormValidity()) {
        return
      }

      let fullMessage = '[IGN - MUROJAT ' + this.getNow() + '] %0A'
      fullMessage += '------------------%0A'
      fullMessage += 'Name: ' + this.name + ' %0A'
      fullMessage += 'Email: ' + this.email + ' %0A'
      fullMessage += 'Phone: ' + this.phone + ' %0A'
      fullMessage += '%0AMessage %0A'
      fullMessage += this.message
      fullMessage += '%0A------------------%0A%0A'

      this.sendMessage(fullMessage)
      this.clearCart()
      this.showAlert()
    },

    clearCart () {
      this.name = ''
      this.email = ''
      this.phone = ''
      this.message = ''
      this.startCheck = false
    },

    showAlert () {
      this.$bvModal.show('submit-success')
    },
    hideModal () {
      this.$bvModal.hide('submit-success')
    }
  }
}
</script>

<template>
  <div>
    <section class="module bg-dark-60" :id="title_id">
      <div class="container">
        <div class="row">
          <div
            data-aos="zoom-in"
            data-aos-duration="1500"
            class="col-sm-6 col-sm-offset-3"
          >
            <h2 class="module-title font-title" v-html="title_text"></h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<style lang="scss" scoped>
// TITLE IMAGE BAR
/*#region */
// $image-title-product: "../../images/ziravorlar.jpeg";
// $image-title-product: "../../images/for-title/gallery02.jpg";
// $image-title-product: "../../images/page_products_1.jpeg";
// $image-title-about: "../../images/bg-dori-1.jpeg";
// $image-title-product: "../../images/medic_tablets_medium.jpeg";


$image-title-product: "../../assets/design/colorful-pills-wooden-spoons.jpeg";
$image-title-contact: "../../images/contact_page_bg_3_medium.jpeg";
$image-title-about: "../../images/medic_tablets_medium.jpeg";
// $image-title-shop: "../../images/ziravorlar.jpeg";
$image-title-shop: "../../assets/design/minimalistic-science-banner-with-pills.jpeg";
$image-title-faq: "../../images/page_faq_1.jpeg";
$image-title-cart: "../../images/page_cart_3.jpeg";
$imgTitle-title-main: "../../../images/fon_2.jpg";

#imgTitle-page-main{
  background: url($imgTitle-title-main) no-repeat;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  height: 500px;
}

#imgTitle-page-products {
  background: url($image-title-product) no-repeat;
  background-position: 70% 75%;
  background-repeat: no-repeat;
  background-size: cover;
}

#imgTitle-page-contact {
  background: url($image-title-contact) no-repeat;
  background-position: 50% 35%;
  background-repeat: no-repeat;
  background-size: cover;
}

#imgTitle-page-about {
  background: url($image-title-about) no-repeat;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: cover;
}

#imgTitle-page-shop {
  background: url($image-title-shop) no-repeat;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s ease;
  align-items: center;

  h2,
  .module-subtitle {
    z-index: 5;
  }
}

#imgTitle-page-faq {
  background: url($image-title-faq) no-repeat;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
}

#imgTitle-page-cart {
  background: url($image-title-cart) no-repeat;
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

.module,
.module-small {
  position: relative;
  height: 380px;
  padding-top: 140px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.bg-dark-60 {
  color: white;
}

.bg-dark-60:before {
  position: absolute;
  background: rgba(34, 34, 34, 0.582);
  content: " ";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.module-title {
  position: relative;
  letter-spacing: 4px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 70px;
}
.font-title {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;
  font-size: 39px;
}

.module-title + .module-subtitle {
  margin-top: -35px;
}

.module-subtitle {
  text-align: center;
  font-size: 16px;
  color: white;
  z-index: 999;
}
.font-serif {
  font-family: Volkhov, "Times New Roman", sans-serif;
  font-style: italic;
}
@media (min-width: 560px) {
  .col-sm-offset-3 {
    margin-left: 25%;
  }
}

@media (max-width: 1080px){
  .module{
    height: 300px;
    text-align: center;
    align-items: center;
  }
  .font-title {
    font-size: 30px;
  }
}
</style>

<script>
export default {
  props: {
    title_id: String,
    title_text: String,
    subtitle_text: String,
  },
  methods: {},
  computed: {
    isPageShop() {
      if (this.title_id === "imgTitle-page-shop") return true;
      else return false;
    },
  },
};
</script>

<!-- <script>
export default {
  props: {
    title_id: String,
    title_text: String,
    subtitle_text: String,
  },
  methods: {},
  computed: {
    isPageShop() {
      if (this.title_id === "imgTitle-page-shop") return true;
      else return false;
    },
  },
};
</script> -->
<template>
  <div class="main">
    <!-- <Main_Navbar /> -->
    <!-- <Title
      title_id="imgTitle-page-main"
      :title_text="$t('page-title.main.title')"
      :subtitle_text="$t('page-title.about.subtitle')"
    /> -->

    <div id="main" class="page-hero bg-image overlay-dark">
      <div class="hero-section">
        <div class="container text-center wow zoomIn">
          <span class="subhead" v-html="$t('page-title.main.title')"></span>

          <!-- <span class="subhead" v-html="title_text">ЗДОРОВЫЙ ОБРАЗ ЖИЗНИ С НАМИ</span> -->
          <h1 class="display-4 text_logo">IGN</h1>
          <h2 class="text_logo">Health Group</h2>
        </div>
      </div>
    </div>

    <div class="page-section py-3 mt-md-n5 custom-index">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4 py-3 py-md-0">
            <div class="card-service wow fadeInUp">
              <div class="circle-shape bg-accent text-white">
                <!-- <span class="mai-bandage"></span> -->
                <!-- <span class="mai-medal-outline"></span> -->
                <span class="fas fa-regular fa-medal"></span>
              </div>
              <!-- <p><span>Chat</span> Лучшая качество </p> -->
              <p>Лучшее качество</p>
            </div>
          </div>
          <div class="col-md-4 py-3 py-md-0">
            <div class="card-service wow fadeInUp">
              <div class="circle-shape bg-primary text-white">
                <span class="mai-shield-checkmark"></span>
                <span class="fas fa-regular fa-heart"></span>
                <!-- <span class="fas fa-shield-heart"></span> -->
              </div>
              <!-- <p><span>One</span>Защита здоровья</p> -->
              <p>Защита здоровья</p>
            </div>
          </div>
          <div class="col-md-4 py-2 py-md-0">
            <div class="card-service wow fadeInUp">
              <div class="circle-shape bg-secondary text-white">
                <!-- <span class="mai-basket"></span> -->
                <!-- <span class="fa-solid fa-building-shield"></span> -->
                <span class="fas fa-clinic-medical"></span>
              </div>
              <!-- <p><span>One</span>-Health Pharmacy</p> -->
              <p>Эффективное производство</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Main />
    <Contact />
    <Footer />

    <!-- <Main /> -->
  </div>
</template>

<script>
// import Main from "../components/Ign/main.vue"
// import Title from "../components/widgets/w-titleImagebar.vue";
// import Main_Navbar from "../components/Titan/t-main-navbar.vue";
import Main from "../components/Titan/t-main.vue";
import Footer from "../components/Titan/t-footer.vue";
import Contact from "../components/Titan/t-contact.vue"
export default {
  components: {
    Main,
    // Main_Navbar,
    // Title,
    Contact,
    Footer,
  },
};
</script>

<!-- <style lang="scss" scoped>
.main {
  position: absolute;
  width: 100%;
  // background: #051a20;
}
</style> -->

<style lang="css" scoped src="../components/Ign/css/theme.css"></style>

